<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <div class="action-box">
      <el-button type="primary" @click="createTemplate">新建模板</el-button>
    </div>
    <el-table
      ref="dictTable"
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
    >
      <el-table-column
        type="index"
        label="序号"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="templateName"
        label="模板名"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-input v-model="scope.row.templateName" style="width: 300px">
            <template slot="append">
              <el-button
                class="el-icon-edit-outline"
                @click="editTemplateName(scope.row)"
              ></el-button>
            </template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="action"
        label="操作"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-button size="mini" @click="handleDetail(scope.row)"
            >查看</el-button
          >
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog top="3%" :visible.sync="showEditDialog" title="编辑模板数据">
      <el-form
        ref="templateForm"
        :model="templateForm"
        :rules="templateRules"
        label="模板内容"
      >
        <el-form-item prop="templateDetail">
          <el-input
            v-model="templateForm.templateDetail"
            type="textarea"
            autosize
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="editLoading" @click="sumbitEdit"
          >确定</el-button
        >
        <el-button @click="clearLandingForm">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog top="3%" :visible.sync="showAddDialog" title="新建模板名称">
      <el-form
        ref="addTemplateForm"
        :model="addTemplateForm"
        :rules="addTemplateRules"
        label="模板名称"
      >
        <el-form-item prop="name" label="模板名称">
          <el-input v-model="addTemplateForm.templateName"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sumbitAdd">确定</el-button>
        <el-button @click="clearAddForm">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HTML from "@/plugins/createHtml.js";
export default {
  data() {
    return {
      queryForm: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      loading: false,
      showEditDialog: false,
      templateForm: {
        templateDetail: "",
      },
      templateRules: {
        templateDetail: [
          { required: true, message: "请填写模板", trigger: "blur" },
        ],
      },
      editLoading: false,
      showAddDialog: false,
      addTemplateForm: {
        templateName: "",
        templateDetail: HTML,
      },
      addTemplateRules: {
        templateName: [
          { required: true, message: "请填写模板名称", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData() {
      this.loading = true;
      this.$http
        .get("/system/template/getPage")
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDetail(e) {
      if ([1, 2, 3, 4, 5, 6, 8, 9].includes(e.id * 1)) {
        this.$http
          .get("/system/template/getTemplateById?templateId=" + e.id)
          .then((response) => {
            if (response.data.code === 200) {
              window.open(response.data.data + "?isViewPre=1");
            }
          });
      } else {
        let routeInfo = this.$router.resolve({
          path: `/template?id=` + e.id,
        });
        window.open(routeInfo.href, "_blank");
      }
    },
    handleEdit(e) {
      if ([1, 2, 3, 4, 5, 6, 8, 9].includes(e.id * 1)) {
        this.templateForm = e;
        this.showEditDialog = true;
      } else {
        let routeInfo = this.$router.resolve({
          path: `/edit?id=` + e.id,
        });
        window.open(routeInfo.href, "_blank");
      }
    },
    handleDelete(e) {
      this.$confirm("是否确定删除模板？该操作不可逆!!!", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: e.id,
        };
        this.$http
          .delete("/system/template/delete", { params: params })
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                message: "删除模板成功",
                type: "success",
                duration: 2000,
              });
              this.queryData();
            }
          });
      });
    },
    sumbitEdit() {
      this.$confirm("是否确定修改模板？该操作可能造成模板不可用!!!", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .post("/system/template/edit", this.templateForm)
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                message: "修改模板成功",
                type: "success",
                duration: 2000,
              });
              this.clearLandingForm();
              this.queryData();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    sumbitAdd() {
      this.$http
        .post("/system/template/add", this.addTemplateForm)
        .then((response) => {
          if (response.data.code == 200) {
            this.$message({
              message: "添加模板成功",
              type: "success",
              duration: 2000,
            });
            this.clearAddForm();
            this.queryData();
          }
        });
    },
    clearLandingForm() {
      this.showEditDialog = false;
      this.$refs.templateForm.resetFields();
      this.templateForm = {
        templateDetail: "",
      };
    },
    clearAddForm() {
      this.showAddDialog = false;
      this.$refs.addTemplateForm.resetFields();
      this.addTemplateForm = {
        templateName: "",
        templateDetail: HTML,
      };
    },
    clearPreview() {
      this.showPreviewDialog = false;
      this.tempHtml = "";
    },
    createTemplate() {
      this.showAddDialog = true;
    },
    editTemplateName(e) {
      this.$confirm("是否确定修改模板名称？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: e.id,
          templateName: e.templateName,
        };
        if (!e.templateDetail) {
          params.templateDetail = HTML;
        }
        this.$http
          .post("/system/template/updateTemplateName", params)
          .then((response) => {
            if (response.data.code == 200) {
              this.$message({
                message: "修改模板名称成功",
                type: "success",
                duration: 2000,
              });
              this.queryData();
            }
          });
      });
    },
  },
};
</script>
<style>
.action-box {
  padding: 20px;
}
</style>
