const html = `<!DOCTYPE html>
<html lang='en' id='html'>
<head>
<meta charset="UTF-8">
<meta name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"/>
<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />
  <script>(function(r,d,s,l){
    var meteor=r.meteor=r.meteor||[];
    meteor.methods=['track','off','on'];
    meteor.factory=function(method){return function(){var args=Array.prototype.slice.call(arguments);args.unshift(method);meteor.push(args);return meteor}};
    for(var i=0;i<meteor.methods.length;i++){var key=meteor.methods[i];meteor[key]=meteor.factory(key)}meteor.load=function(){var js,fjs=d.getElementsByTagName(s)[0];js=d.createElement(s);js.src='https://analytics.snssdk.com/meteor.js/v1/'+l+'/sdk';fjs.parentNode.insertBefore(js,fjs)};meteor.load();if(meteor.invoked){return}meteor.invoked=true;meteor.track('pageview')})
    (window,document,'script','basicCode');
</script>
<style>
  *{margin:0;padding:0}
  html,body{width:100%;height:100%;}
  #app{width:100%;height:100%;background:linear-gradient(rgb(92,165,210),#9198e5);position:absolute;left:0;top:0;bottom:0;right:0;overflow-y:auto;}
  .page-box{position:relative;max-width:500px;min-height:100%;margin:0 auto !important;background-color:#fff;overflow: auto;}
  </style>
  <script src='https://cdn.jsdelivr.net/npm/vue/dist/vue.js'></script>
  <script src='https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js'></script>
  <link rel='stylesheet' href='https://unpkg.com/element-ui/lib/theme-chalk/index.css'>
  <script src='https://unpkg.com/element-ui/lib/index.js'></script>
  <script src='https://ad.mic-dt-vip.com/upload/mk/ad/js/site-view.js'></script> 
  <script>
    function resetRem() {
      var htmlWidth = document.documentElement.clientWidth
      let body = document.getElementById('html')
      if (htmlWidth < 500) {
        body.style.fontSize = htmlWidth / 500 + 'em'
      } else {
        body.style.fontSize = '1em'
      }
    }
    window.onresize = () => {
      resetRem()
    }
  </script>
</head>
<body onload='resetRem()'>
<div id='app'>
    <site-view :prop-data='propData'/>
</div>
<script>
  let copyFct = null;
  new Vue({
    el: '#app',
    data:function(){
        return {
            propData:json_param
        }
    },
  })
</script>
</body>
</html>`;
export default html;
